<template>
  <base-section id="legal" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row>
        <v-col cols="12">
          <v-card color="#D1D9F1" tile flat>
            <v-card-title>{{ $t("legalConcernTitle") }}</v-card-title>
            <v-card-text>
              <i18n path="legalConcern" tag="div" style="white-space: pre-wrap">
                <template v-slot:mail>
                  <a href="mailto: info@gardengrillalicante.com"
                    >info@gardengrillalicante.com</a
                  >
                </template>
                <template v-slot:mail2>
                  <a href="mailto: info@gardengrillalicante.com"
                    >info@gardengrillalicante.com</a
                  >
                </template>
                <template v-slot:link>
                  <a href="https://ec.europa.eu/consumers/odr/" target="_blank"
                    >Online Dispute Resolution | European Commission</a
                  >
                </template>
                <template v-slot:company-name> Garden Grill SL </template>
                <template v-slot:cif> B16896524 </template>
                <template v-slot:address> Doctor Angel Tello Ortiz, 6 local 3 Alicante </template>
              </i18n>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "LegalSection",

  provide: {
    heading: { align: "center" },
  },

  computed: {
    mail() {
      return '<a mailto="info@foodeo.es">info@foodeo.es</a>';
    },
    description() {
      console.log(this.$route);
      return this.$route.meta.description;
    },
  },
};
</script>
